import {
  Building,
  Holiday,
  Reserved,
  Rooms,
  Schedule,
  ScheduleConfig,
  Space,
  SpacePutType,
  RoomDetailsMod,
  SeatPostType,
  Zone,
  ApproveServe,
  CadenceType,
  Individual,
  ReservationStatus,
  SpacesFeatures
} from 'types'

// import { Department } from 'types/department'
import { Reserved as NewReserved } from 'types/newReserved'
import { BaseController } from './BaseController'
import { components } from 'types'
import { FeaturesVersionData } from 'types/featurs'

export class SpacesController extends BaseController {
  checkStatusCode(status: number): boolean {
    return status >= 200 && status < 300
  }

  async getFeatureConfigs(): Promise<FeaturesVersionData> {
    let response = await this.useFetch(
      'GET',
      `get/features`,
      `${this.apiHost}/${this.apiPathFeatures}/versions/1.1.0?appId=spaces-web-admin`
    )
    if (this.checkStatusCode(response.status)) {

      /*
      //DUMMY DATA 
      return {
        featureValue: [
          // {S: 'spaces.seats.manage'},
          // {S: 'spaces.seats.approve'},
          // {S: 'spaces.meetings.manage'},
          // {S: 'spaces.meetings.approve'},
          // {S: 'spaces.touchdowns.manage'},
          // {S: 'spaces.touchdowns.approve'},
          // {S: 'spaces.users.manage'}
        ],
        releaseNotes: 'string',
        releaseDate: 'string',
        maintMode: false
      } */
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getBuilding(): Promise<components["schemas"]["Building"][]> {
    let response = await this.useFetch(
      'GET',
      `get/buildings`,
      `${this.apiHost}/${this.apiPathV2}/building`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getRooms(buildingName: string, roomNumber?: string): Promise<components["schemas"]["RoomList"]> {
    let response = await this.useFetch(
      'GET',
      `get/rooms`,
      `${this.apiHost}/${this.apiPathV2}/room?building_name=${buildingName}${
        roomNumber ? `&room_number=${roomNumber}` : ''
      }`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getOffices(buildingName: string): Promise<Rooms[]> {
    let response = await this.useFetch(
      'GET',
      `get/office`,
      `${this.apiHost}/${this.apiPathV2}/office?building_name=${buildingName}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getSpaces(fromDate: string, spaceID?: string): Promise<any[]> {
    let response = await this.useFetch(
      'GET',
      `get/admin/spaces`,
      `${this.apiHost}/${this.apiPathV2}/admin/space?date_from=${fromDate}${
        spaceID ? `&space_guid=${spaceID}` : ''
      }`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getSchedule(
    spaceId: string,
    date?: string,
    schedule_time?: string
  ): Promise<Schedule[]> {
    let response = await this.useFetch(
      'GET',
      `get/schedule`,
      `${this.apiHost}/${this.apiPathV2}/schedule?space_guid=${spaceId}${
        date ? `&date_from=${date}` : ''
      }${schedule_time ? `&schedule_time=${schedule_time}` : ''}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getScheduleConfig(
    spaceId: string,
    reservation_type?: string
  ): Promise<ScheduleConfig> {
    console.log(
      'TEST::  getScheduleConfig',
      `get/schedule/config-v2/${reservation_type || ''}`,
      `\n${this.apiHost}/${
        this.apiPathV2
      }/schedule/config-v2?space_guid=${spaceId}${
        reservation_type ? `&reservation_type=${reservation_type}` : ''
      }`
    )

    let response = await this.useFetch(
      'GET',
      `get/schedule/config-v2/${reservation_type || ''}`,
      `${this.apiHost}/${this.apiPathV2}/schedule/config-v2?space_guid=${spaceId}${
        reservation_type ? `&reservation_type=${reservation_type}` : ''
      }`
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getSeats(
    buildingId?: string,
    floor?: string,
    roomId?: string
  ): Promise<any[]> {
    let response = await this.useFetch(
      'GET',
      `get/seats`,
      `${this.apiHost}/${this.apiPathV2}/seat?buildingId=${buildingId}&floor=${floor}&roomId=${roomId}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async postSeat(body: SeatPostType): Promise<Space> {
    let response = await this.useFetch(
      'POST',
      'post/seat',
      `${this.apiHost}/${this.apiPathV2}/seat`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async createSpace(
    body: SpacePutType
  ): Promise<components['schemas']['Space']> {
    let response = await this.useFetch(
      'POST',
      'post/admin/space',
      `${this.apiHost}/${this.apiPathV2}/admin/space`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async postScheduleConfigWeekly(body: {
    space_guid: string
    time_from: string
    time_to: string
  }): Promise<any> {
    let response = await this.useFetch(
      'POST',
      'post/schedule/weekly',
      `${this.apiHost}/${this.apiPathV2}/schedule/config/weekly`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async postScheduleConfig(body: {
    space_guid: string
    reservation_type: string
    config: any
  }): Promise<any> {
    let response = await this.useFetch(
      'POST',
      `post/schedule/${body.reservation_type}`,
      `${this.apiHost}/${this.apiPathV2}/schedule/config-v2`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async getReservedSpaces(guid?: any): Promise<Reserved[]> {
    let response = await this.useFetch(
      'GET',
      `get/reserved`,
      `${this.apiHost}/${this.apiPathV2}/reserve${
        guid ? `?space_guid=${guid}` : ''
      }`
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getReservedSpacesViaMITID(mitId?: any): Promise<NewReserved[]> {
    let response = await this.useFetch(
      'GET',
      `get/reserved`,
      `${this.apiHost}/${this.apiPathV2}/reserve${
        mitId ? `?mit_id=${mitId}` : ''
      }`
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getRoomDetails(
    room_number: string,
    fromDate: string,
    toDate: string
  ): Promise<RoomDetailsMod[]> {
    let response = await this.useFetch(
      'GET',
      `get/roomDetail`,
      `${this.apiHost}/${this.apiPathV2}/room/${room_number}/reservations?fromDate=${fromDate}&toDate=${toDate}`
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async postReservedSpaces(body: any): Promise<any> {
    let response = await this.useFetch(
      'POST',
      `post/reserved`,
      `${this.apiHost}/${this.apiPathV2}/reserve`,
      body
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.json()
      return Promise.reject(text)
    }
  }

  async putReservedSpaces(body: any): Promise<any> {
    let response = await this.useFetch(
      'PUT',
      `put/reserved`,
      `${this.apiHost}/${this.apiPathV2}/reserve`,
      body
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.json()
      return Promise.reject(text)
    }
  }

  async deleteInvitee(
    invitee_guid: string,
    reserve_guid: string
  ): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      `delete/invitee`,
      `${this.apiHost}/${this.apiPathV2}/invitee/${invitee_guid}?reserve_guid=${reserve_guid}`
    )

    if (response.status >= 200) {
      return {}
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async deleteReservedSpaces(
    reserve_guid: string,
    timeslot_date_from?: string
  ): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      `delete/reserved`,
      `${this.apiHost}/${this.apiPathV2}/reserve?reserve_guid=${reserve_guid}`
    )

    if (response.status >= 200) {
      return {}
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async deleteReservedSeat(reserve_guid: string): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      `delete/reserved`,
      `${this.apiHost}/${this.apiPathV2}/seat/reservation/${reserve_guid}`
    )

    if (response.status >= 200) {
      return {}
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  // async updateSpace(body: SpacePutType): Promise<Space> {
  async updateSpace(
    body: components['schemas']['Space']
  ): Promise<components['schemas']['Space']> {
    let response = await this.useFetch(
      'PUT',
      'put/space',
      `${this.apiHost}/${this.apiPathV2}/admin/space`,
      {
        ...body
      }
    )
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async deleteSpace(guid: string): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      'delete/admin/space',
      `${this.apiHost}/${this.apiPathV2}/admin/space?space_guid=${guid}`
    )
    if (this.checkStatusCode(response.status)) {
      return {}
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async deleteSeat(guid: string): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      'delete/seat',
      `${this.apiHost}/${this.apiPathV2}/seat/${guid}`
    )
    if (this.checkStatusCode(response.status)) {
      return {}
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  async getHolidays(): Promise<Holiday[]> {
    let response = await this.useFetch(
      'GET',
      'get/holidays',
      `${this.apiHost}/${this.apiPathHolidays}/holiday`
    )
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      let error = await response.json()
      return Promise.reject(error.message)
    }
  }

  //ZONES
  async getZones(buildingName: string): Promise<Zone[]> {
    let response = await this.useFetch(
      'GET',
      `get/zone`,
      `${this.apiHost}/${this.apiPathV2}/zone?building_name=${buildingName}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  // async createZones(body: Zone): Promise<any> {
  async createZones(body: components['schemas']['ZoneResponse']): Promise<any> {
    let response = await this.useFetch(
      'POST',
      `post/zone`,
      `${this.apiHost}/${this.apiPathV2}/zone`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  // async updateZones(body: Zone): Promise<any> {
  async updateZones(
    body: components['schemas']['ZoneUpdateRequest']
  ): Promise<any> {
    let response = await this.useFetch(
      'PUT',
      `put/zone`,
      `${this.apiHost}/${this.apiPathV2}/zone`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async removeZones(zone_guid: string): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      `delete/zone`,
      `${this.apiHost}/${this.apiPathV2}/zone?zone_guid=${zone_guid}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //SEATS
  async getSeatReservations(
    buildingId: string,
    includeDeniedStatus = false,
    dateFrom?: string,
    dateTo?: string,
    individualId?: string,
    zoneName?: string,
    reservationStatusId?: string
  ): Promise<components['schemas']['Reservation'][]> {
    let response = await this.useFetch(
      'GET',
      `get/seat-reservation-admin`,
      `${this.apiHost}/${
        this.apiPathV2
      }/seat/reservation/admin?buildingId=${buildingId}&includeDeniedStatus=${includeDeniedStatus}${
        dateFrom ? `&dateFrom=${dateFrom}` : ''
      }${dateTo ? `&dateTo=${dateTo}` : ''}${
        individualId ? `&individualId=${individualId}` : ''
      }${zoneName ? `&zoneName=${zoneName}` : ''}${
        reservationStatusId ? `&reservationStatusId=${reservationStatusId}` : ''
      }`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async approveSeatRequest(id: string, body: ApproveServe): Promise<any> {
    let response = await this.useFetch(
      'POST',
      `post/seat-reservation-approve`,
      `${this.apiHost}/${this.apiPathV2}/seat/reservation/${id}/approve`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //INDIVIDUAL
  async searchIndividuals(q: string, cadence?: string): Promise<Individual[]> {
    let response = await this.useFetch(
      'GET',
      `get/individuals-search`,
      `${this.apiHost}/${this.apiPathV2}/individual?search=${q}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getIndividuals(
    offset: number,
    quantity: number,
    cadence?: components['schemas']['CadenceType']
  ): Promise<{ total: number; individuals: Individual[] }> {
    let queryName = `get/individuals-${
      cadence?.name!.replaceAll(' ', '_') || 'all'
    }`
    console.log('getIndividuals call fired', cadence, queryName)

    let response = await this.useFetch(
      'GET',
      queryName,
      `${this.apiHost}/${
        this.apiPathV2
      }/individual-list?offset=${offset}&quantity=${quantity}${
        cadence ? `&cadence_type_id=${cadence?.id}` : ''
      }`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getIndividual(id: string): Promise<Individual> {
    let response = await this.useFetch(
      'GET',
      `get/individual`,
      `${this.apiHost}/${this.apiPathV2}/individual/${id}`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async updateIndividual(id: string, body: Individual): Promise<any> {
    let response = await this.useFetch(
      'PUT',
      `put/individual`,
      `${this.apiHost}/${this.apiPathV2}/individual/${id}`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async getIndividualPicture(
    id: string,
    resolution: 'low' | 'high' = 'low'
  ): Promise<{ url: string }> {
    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    }

    let response = await this.useFetch(
      'GET',
      `get/individual-picture-${uuidv4()}`,
      `${this.apiHost}/${this.apiPathV2}/individual/${id}/picture?resolution=${resolution}`
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //SPACE FEATURES
  async getSpacesFeatures(): Promise<SpacesFeatures[]> {
    let response = await this.useFetch(
      'GET',
      `get/available-features`,
      `${this.apiHost}/${this.apiPathV2}/space/available-features`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async postSpacesFeatures(
    body: components['schemas']['FeatureType']
  ): Promise<any> {
    let response = await this.useFetch(
      'POST',
      `post/available-features-${body.featureName}`,
      `${this.apiHost}/${this.apiPathV2}/space/available-features`,
      body
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //ROOM/SPACE IMAGE
  async getSpaceImage(space_guid: string, photoNumber: number = 1): Promise<any> {
    let response = await this.useFetch(
      'GET',
      `get/space-image-${space_guid}-${photoNumber}`,
      `${this.apiHost}/${this.apiPathV2}/space/${space_guid}/photo?photoNumber=${photoNumber}`
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      console.log('getSpaceImage req success', response.status, typeof data)
      return data
    } else {
      console.log('getSpaceImage req failed', response.status)
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async deleteSpaceImage(space_guid: string, photoNumber : number): Promise<any> {
    let response = await this.useFetch(
      'DELETE',
      `delete/space-image-${space_guid}`,
      `${this.apiHost}/${this.apiPathV2}/space/${space_guid}/photo?photoNumber=${photoNumber}`
    )

    if (this.checkStatusCode(response.status)) {
      return {}
    } else {
      console.log('deleteSpaceImage req failed', response.status)
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async updateSpaceImage(
    space_guid: string,
    file: string,
    photoNumber : number
  ): Promise<any> {
    let response = await this.useFetch(
      'PUT',
      `put/space-image-${space_guid}-${photoNumber}`,
      `${this.apiHost}/${this.apiPathV2}/space/${space_guid}/photo?photoNumber=${photoNumber}`,
      { base64Data: file }
    )

    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      console.log('PUT image req success', response.status, typeof data)
      return data
    } else {
      console.log('getSpaceImage req failed', response.status)
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //DEPARTMENTS
  async getDepartments(): Promise<components['schemas']['Department'][]> {
    let response = await this.useFetch(
      'GET',
      `get/departments`,
      `${this.apiHost}/${this.apiPathV2}/department`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //CADENCE
  async getCadenceTypes(): Promise<CadenceType[]> {
    let response = await this.useFetch(
      'GET',
      `get/cadence-types`,
      `${this.apiHost}/${this.apiPathV2}/cadence-types`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //SEAT RESERVATION STATUS
  async getSeatReservationStatus(): Promise<ReservationStatus[]> {
    let response = await this.useFetch(
      'GET',
      `get/seat-reservation-status`,
      `${this.apiHost}/${this.apiPathV2}/seat/reservation-status`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  //INDIVIDUAL TYPES
  async getIndividualTypes(): Promise<[]> {
    let response = await this.useFetch(
      'GET',
      `get/individual-types`,
      `${this.apiHost}/${this.apiPathV2}/individual-types`
    )
    if (this.checkStatusCode(response.status)) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }
}

const spacesController = new SpacesController()
export default spacesController
