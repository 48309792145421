import { Action, action } from 'easy-peasy'
import moment from 'moment'
import { components, pageViewSelection } from 'types'
// import { Zone } from 'types/zone'

interface State {
  pageView: pageViewSelection
  showDrawer: boolean
  date: string
  editingZone: components['schemas']['ZoneResponse'] | null
  selectedZoneDetails: components['schemas']['ZoneResponse'] | null
  refreshZones: boolean
}
interface Actions {
  setPageView: Action<this, pageViewSelection>
  setShowDrawer: Action<this, boolean>
  setDate: Action<this, string>
  setEditingZone: Action<this, components['schemas']['ZoneResponse'] | null>
  setSelectedZoneDetails: Action<
    this,
    components['schemas']['ZoneResponse'] | null
  >
  setRefreshZones: Action<this, boolean>
}

export interface IDeskModel extends State, Actions {}

export const DeskModel: IDeskModel = {
  pageView: undefined,
  setPageView: action((state, payload) => {
    state.pageView = payload
  }),
  showDrawer: false,
  setShowDrawer: action((state, payload) => {
    state.showDrawer = payload
  }),

  date: moment().format('YYYY-MM-DD'),
  setDate: action((state, payload) => {
    state.date = payload
  }),

  editingZone: null,
  setEditingZone: action((state, payload) => {
    state.editingZone = payload
  }),

  selectedZoneDetails: null,
  setSelectedZoneDetails: action((state, payload) => {
    state.selectedZoneDetails = payload
  }),

  refreshZones: false,
  setRefreshZones: action((state, payload) => {
    state.refreshZones = payload
  })
}
