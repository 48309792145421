import { persist } from 'easy-peasy'

// APP SPECIFIC STORES - EDIT THESE OR ADD MORE HERE:
import { IAppConfigModel, AppConfigModel } from './AppConfig'
import { IGlobalModel, GlobalModel } from './global'
import { ILocalStorageModel, LocalStorageModel } from './LocalPresist'
import { ISessionStorageModel, SessionStorageModel } from './SessionPresist'
import { IProfileModel, ProfileModel } from './profile'
import { CounterModel, counterModel } from './counter'
import { IReservationModel, ReservationModel } from './reservation'
import { IDeskModel, DeskModel } from './desk'
import { IRoomsModel, RoomsModel } from './rooms'
import { IUsersModel, UsersModel } from './users'

export interface Model {
  counterModel: CounterModel
  AppConfigModel: IAppConfigModel
  GlobalModel: IGlobalModel
  LocalStorageModel: ILocalStorageModel
  ProfileModel: IProfileModel
  SessionStorageModel: ISessionStorageModel
  ReservationModel: IReservationModel
  UsersModel: IUsersModel
  DeskModel: IDeskModel
  RoomsModel: IRoomsModel
}

export const model: Model = {
  counterModel,
  // FOR APP CONFIG MANEGEMENT - AppConfigModel
  AppConfigModel,
  // FOR GLOBAL STATE MANAGEMENT - GlobalModel
  GlobalModel,
  // FOR PROFILE STATE MANAGEMENT - ProfileModel
  ProfileModel,
  // FOR LOCALSTORAGE MANAGEMENT - LocalStorageModel
  LocalStorageModel: persist(LocalStorageModel, {
    storage: 'localStorage'
  }),
  // FOR SESSIONSTORAGE MANAGEMENT - SESSIONSTORAGE
  SessionStorageModel: persist(SessionStorageModel, {
    storage: 'sessionStorage'
  }),
  ReservationModel,
  DeskModel,
  RoomsModel,
  UsersModel
}
