import { Action, action } from 'easy-peasy'
import { Space } from 'types'
import { Access } from 'types/access'
// import { CadenceType } from 'types/cadenceType'
import { IndividualType } from 'types/individualType'
import { ReservationStatus } from 'types/reservationStatus'
import { Features } from 'types/featurs'
// import { Department } from 'types/department'
import { components } from 'types'

interface State {
  auth: object
  access: Access
  isLoading: boolean
  availableSpaces: string[]
  drawer: DrawerController
  addSpaceDrawer: DrawerController
  selectedSpace: Space | null
  selectedDate: string
  banner: BannerType
  // cadenceTypes: CadenceType[]
  cadenceTypes: components['schemas']['CadenceType'][]
  seatReservationStatus: ReservationStatus[]
  individualTypes: IndividualType[]
  features: Features
  // departments: Department[]
  departments: components['schemas']['Department'][]
  buildings: components["schemas"]["Building"][]
  spaceFeatures: components['schemas']['FeatureType'][]
  // spaceFeatures: SpacesFeatures[]
}

interface Actions {
  setAuth: Action<this, object>
  setAccess: Action<this, Access>
  setIsLoading: Action<this, boolean>
  setAvailableSpaces: Action<this, string[]>
  setDrawer: Action<this, DrawerController>
  setAddDrawer: Action<this, DrawerController>
  setSelectedSpace: Action<this, Space | null>
  setSelectedDate: Action<this, string>
  setBanner: Action<this, BannerType>
  setCadenceTypes: Action<this, components['schemas']['CadenceType'][]>
  setSeatReservationStatus: Action<this, ReservationStatus[]>
  setIndividualTypes: Action<this, IndividualType[]>
  setFeature: Action<this, Features>
  // setDepartments: Action<this, Department[]>
  setDepartments: Action<this, components['schemas']['Department'][]>
  setBuildings: Action<this, components["schemas"]["Building"][]>
  // setSpaceFeatures: Action<this, SpacesFeatures[]>
  setSpaceFeatures: Action<this, components['schemas']['FeatureType'][]>
}

interface DrawerController {
  visible: boolean
  header?: string
  subHeader?: string
  schedule_time?: string
  space_guid?: string
  date_from?: string
  maxValue?: number
  primaryFillAmount?: number
  isEditing?: boolean
  editingID?: string
}

interface BannerType {
  visible: boolean
  type?: 'success' | 'info' | 'warning' | 'error'
  text?: string
}

// eslint-disable-next-line
interface Thunks {}

export interface IGlobalModel extends State, Actions, Thunks {}

const getInitialCalendarValue = () => {
  let searchDate = new Date()
  let dateString = `${searchDate.getFullYear()}-${
    searchDate.getMonth() + 1 < 10
      ? '0' + (searchDate.getMonth() + 1)
      : searchDate.getMonth() + 1
  }-${
    searchDate.getDate() < 10
      ? '0' + searchDate.getDate()
      : searchDate.getDate()
  }`
  return dateString
}

export const GlobalModel: IGlobalModel = {
  auth: {},
  access: {
    admin: false,
    seat_approver: false,
    user: false,
    manager: false
  },
  isLoading: true,
  availableSpaces: [],
  drawer: {
    visible: false
  },
  addSpaceDrawer: {
    visible: false
  },
  selectedSpace: null,
  selectedDate: getInitialCalendarValue(),
  banner: {
    visible: false
  },
  cadenceTypes: [],
  seatReservationStatus: [],
  individualTypes: [],
  features: {
    meetings: {
      manage: false,
      approve:false
    },
    touchdowns: {
      manage: false,
      approve:false
    },
    seats: {
      manage: false,
      approve:false
    },
    users: {
      manage: false,
      approve:false
    }
  },
  departments: [],
  buildings: [],
  spaceFeatures: [],

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setAccess: action((state, payload) => {
    state.access = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setAvailableSpaces: action((state, payload) => {
    state.availableSpaces = payload
  }),
  setDrawer: action((state, payload) => {
    state.drawer = payload
  }),
  setAddDrawer: action((state, payload) => {
    state.addSpaceDrawer = payload
  }),
  setSelectedSpace: action((state, payload) => {
    state.selectedSpace = payload
  }),
  setSelectedDate: action((state, payload) => {
    state.selectedDate = payload
  }),
  setBanner: action((state, payload) => {
    state.banner = payload
  }),
  setCadenceTypes: action((state, payload) => {
    state.cadenceTypes = payload
  }),
  setSeatReservationStatus: action((state, payload) => {
    state.seatReservationStatus = payload
  }),
  setIndividualTypes: action((state, payload) => {
    state.individualTypes = payload
  }),
  setFeature: action((state, payload) => {
    state.features = payload
  }),
  setSpaceFeatures: action((state, payload) => {
    state.spaceFeatures = payload
  }),
  setDepartments: action((state, payload) => {
    state.departments = payload
  }),
  setBuildings: action((state, payload) => {
    state.buildings = payload
  })
}
