import { Action, action, Computed, computed } from 'easy-peasy'
import moment from 'moment'
import { EnabledDisabledScheduleConfigs, pageViewSelection, ReservationMod, ScheduleConfigItems } from 'types'
import { components } from 'types'

type RefreshDataKeys = 'reservations' | 'rooms'
type RefreshDataProps = { [key in RefreshDataKeys]?: boolean }

export const emptyScheduleConfiItem: ScheduleConfigItems = {
  inclusions: [],
  exclusions: []
}

export type CalendarType = 'DATE' | 'DAY'
export type ScheduleType = "TOUCHDOWN" | "MEETING"
interface State {
  pageView: pageViewSelection | undefined
  refreshData: RefreshDataProps
  showDrawer: boolean
  showManageDrawer: boolean
  date: string
  editingRoom: components['schemas']['Space'] | null
  selectedRoomDetails: components['schemas']['Space'] | null
  selectedScheduleType: ScheduleType
  selectedCalendarType: CalendarType
  meetingConfig: ScheduleConfigItems
  touchDownConfig: ScheduleConfigItems
  manageReservation: string | null | undefined
  manageReservationData: Computed<State, any | null>
  scheduleConfig: Computed<State, EnabledDisabledScheduleConfigs>
  roomReservations: ReservationMod[] | undefined
}

interface Actions {
  setPageView: Action<this, pageViewSelection | undefined>
  setRefreshData: Action<this, RefreshDataProps>
  setShowDrawer: Action<this, boolean>
  setShowManageDrawer: Action<this, boolean>
  setDate: Action<this, string>
  setEditingRoom: Action<this, components['schemas']['Space'] | null>
  setSelectedRoomDetails: Action<this, components['schemas']['Space'] | null>
  setSelectedScheduleType: Action<this, ScheduleType>
  setSelectedCalendarType: Action<this, CalendarType>
  setMeetingConfig: Action<this, ScheduleConfigItems>
  setTouchDownConfig: Action<this, ScheduleConfigItems>
  setManageReservation: Action<this, string | null | undefined>
  setRoomReservations: Action<this, ReservationMod[] | undefined>
}

export interface IRoomsModel extends State, Actions {}

export const RoomsModel: IRoomsModel = {
  pageView: undefined,
  setPageView: action((state, payload) => {
    state.pageView = payload
  }),
  refreshData: {
    reservations: false,
    rooms: false
  },
  setRefreshData: action((state, payload) => {
    state.refreshData = { ...state.refreshData, ...payload }
  }),
  showDrawer: false,
  setShowDrawer: action((state, payload) => {
    state.showDrawer = payload
  }),
  showManageDrawer: false,
  setShowManageDrawer: action((state, payload) => {
    state.showManageDrawer = payload
  }),

  date: moment().format('YYYY-MM-DD'),
  setDate: action((state, payload) => {
    state.date = payload
  }),

  editingRoom: null,
  setEditingRoom: action((state, payload) => {
    state.editingRoom = payload
  }),

  selectedRoomDetails: null,
  setSelectedRoomDetails: action((state, payload) => {
    state.selectedRoomDetails = payload
  }),

  roomReservations: undefined,
  setRoomReservations: action((state, payload) => {
    state.roomReservations = payload
  }),

  selectedScheduleType: "MEETING",
  setSelectedScheduleType: action((state, payload) => {
    state.selectedScheduleType = payload
  }),

  selectedCalendarType: "DAY",
  setSelectedCalendarType: action((state, payload) => {
    state.selectedCalendarType = payload
  }),

  meetingConfig: emptyScheduleConfiItem,
  setMeetingConfig: action((state, payload) => {
    state.meetingConfig = payload
  }),

  touchDownConfig: emptyScheduleConfiItem,
  setTouchDownConfig: action((state, payload) => {
    state.touchDownConfig = payload
  }),

  manageReservation: null,
  setManageReservation: action((state, payload) => {
    state.manageReservation = payload
  }),

  manageReservationData: computed((state) => {
    let reservationData = null
    if (state.manageReservation && state.roomReservations != null && state.roomReservations.length > 0) {
      reservationData = [...state.roomReservations].find((o) => o.timeslot_date_from === state.manageReservation)
    }
    return reservationData
  }),

  scheduleConfig: computed((state) => {
    let configs: EnabledDisabledScheduleConfigs = {
      enabled: emptyScheduleConfiItem,
      disabled: emptyScheduleConfiItem
    }

    if (state.selectedScheduleType === "TOUCHDOWN") {
      //Touchdown
      configs = {
        enabled: state.touchDownConfig,
        disabled: state.selectedCalendarType === "DAY" ? state.meetingConfig : emptyScheduleConfiItem
      }
    }

    if (state.selectedScheduleType === "MEETING") {
      //MEETING
      configs = {
        enabled: state.meetingConfig,
        disabled: state.selectedCalendarType === "DAY" ? state.touchDownConfig : emptyScheduleConfiItem
      }
    }

    return configs
  })
}
