import { Action, action } from 'easy-peasy'
import { Individual } from 'types/individual'
// import { SeatReservation } from 'types/seatReservation'
import { components } from 'types'

type UsersViews = 'requests' | 'users'
export type View =
  | 'All'
  | 'Full time'
  | 'Twice a week'
  | 'Once a week'
  | 'Search'

export interface ViewIndividualsState {
  [key: string]: {
    individuals: Individual[]
    offSet: number
    total: number
  }
}

interface State {
  view: UsersViews
  selectedSeatUser: components['schemas']['Reservation'] | null
  selectedIndividual: Individual | null
  viewSelected: View
  viewIndividualsState: ViewIndividualsState
}
interface Actions {
  setView: Action<this, UsersViews>
  setSelectedSeatUser: Action<this, components['schemas']['Reservation'] | null>
  setSelectedIndividual: Action<this, Individual | null>
  setViewSelected: Action<this, View>
  setViewIndividualsState: Action<this, ViewIndividualsState>
}

export interface IUsersModel extends State, Actions {}

export const UsersModel: IUsersModel = {
  view: 'requests',
  setView: action((state, payload) => {
    state.view = payload
  }),

  selectedSeatUser: null,
  setSelectedSeatUser: action((state, payload) => {
    state.selectedSeatUser = payload
  }),

  selectedIndividual: null,
  setSelectedIndividual: action((state, payload) => {
    console.log('show drawer setSelectedIndividual', payload)
    state.selectedIndividual = payload
  }),

  viewSelected: 'Search',
  setViewSelected: action((state, payload) => {
    state.viewSelected = payload
  }),

  viewIndividualsState: {},
  setViewIndividualsState: action((state, payload) => {
    state.viewIndividualsState = { ...state.viewIndividualsState, ...payload }
  })
}
