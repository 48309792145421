import { BaseApiController, Region, RegionalConfig, AuthProvider, AppConfig } from '@mit/aws-react'
import appConfig from '../../app-config'

// Override any methods from BaseApiController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  public readonly apiPath: string
  public readonly apiPathV2: string
  public readonly apiPathHolidays: string
  public readonly appConfig: AppConfig
  public readonly apiHost: string
  public readonly apiPathDigitalId: string
  public readonly apiPathFeatures: string
  private readonly activeRegion: RegionalConfig

  public constructor() {
    super()
    this.appConfig = (appConfig as unknown) as AppConfig
    const region = new Region(this.appConfig)

    this.activeRegion = region.getActiveRegionConfig()
    this.apiHost = `https://${this.activeRegion.api.domain}`
    this.apiPathHolidays = this.activeRegion.api.paths.scheduling
    this.apiPath = this.activeRegion.api.paths.spaces
    this.apiPathV2 = this.activeRegion.api.paths.spacesV2
    this.apiPathDigitalId = this.activeRegion.api.paths['digital-id']
    this.apiPathFeatures = this.activeRegion.api.paths.features
  }

  public checkStatusCode(status: number): boolean {
    return status >= 200 && status < 300
  }

  /*
    If you do not use cognito, implement your authorization header logic here
  */
  public async getAuthorizationHeaders(): Promise<Record<string, string>> {
    const token = await new AuthProvider(this.activeRegion).getToken()

    return {
      Authorization: `Bearer ${token}`
    }
  }
}
