import { BaseController } from './BaseController'

import { Profile } from 'types'
import { Access } from 'types/access'

export class ProfileController extends BaseController {
  public async getProfile (): Promise<Profile> {
    const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProfilePicture (): Promise<Blob> {
    const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

    if (response.status === 200) {
      return response.blob()
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  async getPicture (mitId?: string, highResolution?: boolean, index?:number): Promise<any> {
    const response = await this.useFetch('GET', `profilePicture-${mitId}-${index}`, `${this.apiHost}/${this.apiPathDigitalId}/picture/${mitId}${highResolution ? "?resolution=high" : ""}`)

    if (response.status === 200) {
      return response.blob()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getPeople(searchTerm: string): Promise<any> {

		let response = await this.useFetch('GET', 'lookups/mit-person', `${this.apiHost}/${this.apiPathDigitalId}/search?q=${searchTerm}`);

		if (response.status === 200) {
			let data = await response.json();

			return data;
		} else {
			let text = await response.text();
			return Promise.reject(text);
		}

	}

	async getAccess(): Promise<Access> {
		let response = await this.useFetch('GET', 'get/access', `${this.apiHost}/${this.apiPathV2}/access`);

		if (response.status === 200) {
			return await response.json();
		} else {
			let text = await response.text();
			return Promise.reject(text);
		}
	}
}
