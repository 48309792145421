import { appInitialized } from '@mit/aws-react'
import { ProfileController } from 'api/controller'
import spacesController from 'api/controller/SpacesController'
import { useStoreActions, useStoreState } from 'store'
import { Features } from 'types/featurs'
const controller = new ProfileController()

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const { setSeatReservationStatus, setCadenceTypes, setIndividualTypes, setAccess, setFeature, setDepartments, setBuildings, setSpaceFeatures } = useStoreActions(
    (action) => action.GlobalModel
  )
  const { features } = useStoreState((action) => action.GlobalModel)

  const initializeApp = async (): Promise<void> => {
    // Simulate api call. Don't use timeout
    // You can access easy peasy state in this hook
    const stringsToObject = (featuresArr: string[], defaultVal = {}) => {
      return featuresArr.reduce((acc: { [key: string]: string | true | {} }, item) => {
        let currentStep = acc
        const split = item.replace('spaces.', '').split('.')
        split.forEach((item, index) => {
          currentStep = currentStep[item] = currentStep[item] || (index === split.length - 1 ? true : {})
        })

        return acc
      }, {})
    }

    Promise.all([
      spacesController.getFeatureConfigs(),
      spacesController.getCadenceTypes(),
      spacesController.getIndividualTypes(),
      spacesController.getDepartments(),
      spacesController.getSpacesFeatures(),
      spacesController.getSeatReservationStatus(),
      spacesController.getBuilding()
    ])
      .then(([featureRes, cadenceTypesRes, individualTypesRes, departmentsRes, spacesFeaturesRes, seatReservationStatusRes, buildingsRes]) => {

        if (featureRes) {
          let featuresArr = featureRes.featureValue.map((o) => o.S)
          let featuresObj = stringsToObject(featuresArr) as unknown as Features
          let mergedFeatures = { ...features, ...featuresObj }
          setFeature(mergedFeatures)

          appInitialized({
            isLoading: false,
            hasAccess: 
              mergedFeatures.seats.approve ||
              mergedFeatures.touchdowns.approve ||
              mergedFeatures.meetings.approve ||
              mergedFeatures.users.manage ||
              mergedFeatures.touchdowns.manage ||
              mergedFeatures.meetings.manage ||
              mergedFeatures.seats.manage,
            hasError: false
          })
        } else {
          appInitialized({
            isLoading: false,
            hasAccess: false,
            hasError: true
          })
        }

        if (cadenceTypesRes) setCadenceTypes(cadenceTypesRes)
        if (individualTypesRes) setIndividualTypes(individualTypesRes)
        if (departmentsRes) setDepartments(departmentsRes)
        if (spacesFeaturesRes) setSpaceFeatures(spacesFeaturesRes)
        if (seatReservationStatusRes) setSeatReservationStatus(seatReservationStatusRes)
        if (buildingsRes) setBuildings(buildingsRes)
      })
      .catch((err) => {
        console.error('FAILED TO INITIALIZE APP', err)
      })
  }

  return { initializeApp }
}
