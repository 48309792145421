import { Action, action } from 'easy-peasy'
import { Individual } from 'types/individual'
import { components } from 'types'

type ReservationViews = 'requests' | 'users'
export type View = 'Pending' | 'all' | 'Approved' | 'Denied'

interface State {
  view: ReservationViews
  selectedSeatReservation: components['schemas']['Reservation'] | null
  selectedIndividual: Individual | null
  viewSelected: View
}
interface Actions {
  setView: Action<this, ReservationViews>
  setSelectedSeatReservation: Action<
    this,
    components['schemas']['Reservation'] | null
  >
  setSelectedIndividual: Action<this, Individual | null>
  setViewSelected: Action<this, View>
}

export interface IReservationModel extends State, Actions {}

export const ReservationModel: IReservationModel = {
  view: 'requests',
  setView: action((state, payload) => {
    state.view = payload
  }),

  selectedSeatReservation: null,
  setSelectedSeatReservation: action((state, payload) => {
    state.selectedSeatReservation = payload
  }),

  selectedIndividual: null,
  setSelectedIndividual: action((state, payload) => {
    state.selectedIndividual = payload
  }),

  viewSelected: 'Pending',
  setViewSelected: action((state, payload) => {
    state.viewSelected = payload
  })
}
