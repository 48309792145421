import { Action, action } from 'easy-peasy'
import { Profile } from 'types'

interface State {
  profile: Profile
  profilePicture: string
  isStudent: boolean
	hasApplications: boolean
}
interface Actions {
  updateProfile: Action<this, Profile>
  updateProfilePicture: Action<this, string>
  setIsStudent: Action<this, boolean>
	setHasApplications: Action<this, boolean>
}

export interface IProfileModel extends State, Actions {}

export const ProfileModel: IProfileModel = {
  profile: {
    department: '',
    display_name: '',
    email: '',
    first_name: '',
    initials: '',
    kerberos: '',
    kerberos_id: '',
    krb_name: '',
    last_name: '',
    mit_id: '',
    mitid: '',
    office_location: '',
    phone_number: '',
    source: ''
  },
  profilePicture: '',
  isStudent: false,
	hasApplications: false,

  // ACTIONS
  updateProfile: action((state, payload) => {
    state.profile = { ...state.profile, ...payload }
  }),
  updateProfilePicture: action((state, payload) => {
    state.profilePicture = payload
  }),
  setIsStudent: action((state, payload) => {
		state.isStudent = payload
	}),
	setHasApplications: action((state, payload) => {
		state.hasApplications = payload
	})
}
